<template>
  <div class="view pa24">
    <div class="operation-left">
      <!-- <el-button class="mr10" type="primary" @click="showTaskAddModel"
        >新增任务</el-button
      > -->
    </div>
    <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="mainTaskName" align="center" label="选择的任务" show-overflow-tooltip />
        <el-table-column prop="taskName" align="center" label="任务名称" show-overflow-tooltip />
        <el-table-column prop="remark" align="center" label="说明" show-overflow-tooltip />
        <el-table-column prop="pointNum" align="center" label="奖励积分" width="90" show-overflow-tooltip />
        <el-table-column show-overflow-tooltip prop="pointType" align="center" label="重置方式" width="90">
          <template slot-scope="scope">
            {{ scope.row.pointType | getPointType }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="pointTimes" align="center" label="可完成数" width="90">
          <template slot-scope="scope">
            {{ scope.row.pointTimes | getPointTimes }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="status" align="center" label="状态" width="90">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value="0" :inactive-value="1"
              :disabled="!('linkId' in scope.row)" @change="setStatus($event, scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="80">
          <template slot-scope="scope">
            <el-button v-if="'linkId' in scope.row" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
            <el-button v-else type="text" @click="setStatus(0, scope.row)">启用</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showAddTask" :destroy-on-close="true"
      width="500px" center>
      <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
        <!-- <el-form-item label="选择任务" prop="taskId">
          <el-select
            placeholder="请选择任务"
            v-model="formData.taskId"
            @change="changeTask"
          >
            <el-option
              :disabled="row.disabled"
              v-for="(row, index) in memberTaskData"
              :key="index"
              :value="row.taskId"
              :label="row.taskName"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="任务名称" prop="taskName">
          <el-input style="width: 222px" placeholder="请输入任务名称" v-model="formData.taskName" type="text" />
        </el-form-item>
        <el-form-item label="奖励积分" prop="pointNum">
          <el-input-number v-model="formData.pointNum" :min="1" label="请输入奖励积分"></el-input-number>
        </el-form-item>
        <el-form-item label="重置方式" prop="pointType">
          <el-radio-group v-model="formData.pointType" size="small">
            <el-radio-button :label="0">每天</el-radio-button>
            <el-radio-button :label="1">不重置</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="可完成数" prop="pointTimes">
          <el-switch v-model="pointTimes" active-text="多次" inactive-text="无限次" :active-value="1" :inactive-value="0"
            class="mr10">
          </el-switch>
          <el-input-number v-if="pointTimes == 1" v-model="formData.pointTimes" :min="1" label="请输入可完成数">
          </el-input-number>
          <!-- <el-input
            type="number"
            v-model="formData.pointTimes"
            placeholder="请输入可完成数"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="说明" prop="remark">
          <el-input :rows="5" placeholder="请输入说明" v-model="formData.remark" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddTask = false">取 消</el-button>
        <el-button type="primary" @click="addMemberTask">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectMemberTaskLink,
  // selectMemberTask,
  LinkMemberTask,
} from "@/api/member";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
  },
  data() {
    return {
      pageSize: 10, //显示条数
      loading: false, //表格加载
      showAddTask: false,
      tableData: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      memberTaskData: [], //任务类型
      memberTaskObj: {},
      pointTypeData: {
        0: "每天",
        1: "不重置",
      },
      modelTitle: "",
      pointTimes: 0,
      formData: {
        pointNum: "",
        pointTimes: 0,
        pointType: 0,
        taskName: "",
        taskId: "",
        companyId: this.$store.state.loginRoot.userInfo.companyId,
        status: "",
        remark: "",
      },
      rules: {
        levelName: {
          required: true,
          trigger: "blur",
          message: "请输入等级名称",
        },
        pointNum: {
          required: true,
          trigger: "blur",
          message: "请输入奖励积分",
        },
        pointType: {
          required: true,
          trigger: "change",
          message: "请选择重置方式",
        },
        taskName: {
          required: true,
          trigger: "change",
          message: "请输入任务名称",
        },
        taskId: {
          required: true,
          trigger: "change",
          message: "请选择任务",
        },
      },
      taskIds: [],
      formType: "",
    };
  },
  filters: {
    getPointType(type) {
      let pointTypeData = {
        0: "每天",
        1: "不重置",
      };
      return pointTypeData[type];
    },
    getPointTimes(number) {
      if (number === 0) {
        return "无限次";
      } else {
        return number;
      }
    },
  },
  async created() {
    await this.selectMemberTaskLink();
    // this.selectMemberTask();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    changeTask(id) {
      let data = this.memberTaskObj[id];
      this.formData.taskName = data.taskName;
      this.formData.pointNum = data.pointNum;
      this.formData.pointTimes = data.pointTimes;
      this.formData.pointType = data.pointType;
    },
    /**@method 显示新增会员任务模态框 */
    showTaskAddModel() {
      this.showAddTask = true;
      this.modelTitle = "新增会员任务";
      this.pointTimes = 0;
      this.formData = {
        pointNum: "",
        pointTimes: "",
        pointType: 0,
        taskName: "",
        taskId: "",
        companyId: this.userInfo.companyId,
        // companyId: 2,
        status: "",
      };
      this.formType = "add";
    },

    /**@method 任务数据 */
    // async selectMemberTask() {
    //   let res = await selectMemberTask();
    //   if (res.code === 200) {
    //     let memberTaskData = [];
    //     let memberTaskObj = {};
    //     for (var row of res.data) {
    //       if (this.taskIds.indexOf(row.taskId) != -1) {//判断任务在该公司是否唯一
    //         row.disabled = true;
    //       } else {
    //         row.disabled = false;
    //       }
    //       memberTaskData.push(row);
    //       memberTaskObj[row.taskId] = row;
    //     }
    //     this.memberTaskData = memberTaskData;
    //     this.memberTaskObj = memberTaskObj;
    //   }
    // },
    /**@method 获取会员任务列表 */
    async selectMemberTaskLink(payload) {
      let params = {
        companyId: this.userInfo.companyId,
        // companyId: 2,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await selectMemberTaskLink(params,9);
        this.loading = false;

        const { data } = result;
        // let taskIds = [];
        let arr = [];
        for (let row of data) {//转换数据格式
          // taskIds.push(Number(row.taskId));
          let obj = {
            mainTaskName: row.oldTaskName,
            pointNum: row.pointNum || row.pointNum === 0 ? row.pointNum : row.oldPointNum,
            pointTimes: row.pointTimes || row.pointTimes === 0 ? row.pointTimes : row.oldPointTimes,
            pointType: row.pointType || row.pointType === 0 ? row.pointType : row.oldPointType,
            taskId: row.taskId,
            taskName: row.taskName ? row.taskName : "-",
            remark:row.remark? row.remark : "-",
          };
          if (row.status || row.status === 0) {
            obj.status = row.status;
          }
          if (row.linkId) {
            obj.linkId = row.linkId;
          }
          arr.push(obj);
        }
        this.tableData = arr;
        // this.taskIds = taskIds;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    /**@method 新增会员任务 */
    addMemberTask() {
      //新增会员任务
      //修改添加会员等级
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.pointTimes === 0) {
            this.formData.pointTimes = this.pointTimes;
          }
          if (this.formType === "add") {
            this.submitForm(this.formData, "任务新增完成");
          } else if (this.formType === "update") {
            this.submitForm(this.formData, "任务编辑完成");
          }
        }
      });
    },
    showUpdateModel(row) {
      //-console.log(row);
      this.showAddTask = true;
      this.modelTitle = "编辑会员任务";
      this.formData = {
        pointNum: row.pointNum,
        pointTimes: row.pointTimes,
        pointType: row.pointType,
        taskName: row.taskName,
        taskId: row.taskId,
        companyId: this.userInfo.companyId,
        // companyId: 2,
        status: row.status,
        remark: row.remark
      };
      this.pointTimes = row.pointTimes ? 1 : 0;
      this.formType = "update";
    },
    /**@method 修改状态 */
    setStatus(val, row) {
      let formData = {
        companyId: this.userInfo.companyId,
        // companyId: 2,
        taskId: row.taskId,
        status: val,
      };
      this.submitForm(formData);
    },
    /**@method 关联任务数据 */
    async submitForm(formData, message) {
      let res = await LinkMemberTask(formData,9);
      if (res.code === 200) {
        if (message) {
          this.$message({
            type: "success",
            message: message,
          });
        }
        this.selectMemberTaskLink();
        this.showAddTask = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switchInput {
  display: flex;

  .selectType {
    width: 150px;
  }

  .levelPrice {
    width: 200px;
  }

  .levelTime {
    width: 200px;
  }
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
    width: 95px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}

.btnDel {
  color: #fd634e;
}
</style>